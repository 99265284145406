import {
 MaybeRef, onMounted, onUnmounted, toValue,
} from 'vue';

export const useEventListener = (
    target: MaybeRef<HTMLElement | Window | Document>,
    eventType: keyof GlobalEventHandlersEventMap | 'hashchange' | 'pageshow',
    callback: (event?: Event) => void,
    options?: boolean | AddEventListenerOptions,
) => {
    if (!target) {
        // eslint-disable-next-line no-console
        console.error('Invalid target.');
    }

    onMounted(() => {
        toValue(target)?.addEventListener(eventType, callback, options);
    });

    onUnmounted(() => {
        toValue(target)?.removeEventListener(eventType, callback, options);
    });
};
